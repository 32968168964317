import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import { Colis } from "src/entities/Colis";
import { Recu } from "src/entities/Recu";
import { Statut } from "src/entities/Statut";
import JsBarcode from "jsbarcode/bin/JsBarcode";
import { async } from "@angular/core/testing";
import { formatDate } from "@angular/common";
import { environment } from "src/environments/environment";
import { Client } from "./Clients";

export class Pdf {
  DOMAIN = environment.domain;
  FONT = environment.font;
  SSL = environment.ssl;

  constructor() {}

  textToBase64Barcode(text) {
    var canvas = document.createElement("canvas");
    JsBarcode(canvas, text, { format: "CODE128" });
    return canvas.toDataURL("image/png");
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }



     async GeneratePdfVoucherA4(colis_list: Colis[]) {
                var image_logo =await this.getBase64ImageFromURL(
            this.SSL +"://client." +this.DOMAIN +"/logo1.png?auto=compress&cs=tinysrgb&dpr=1&w=80"
        );
        const transport=JSON.parse(localStorage.getItem("info"))
        var outputItems = [];
        colis_list.forEach((element, index) => {
            for (var _i = 0; _i < element.nbre; _i++) {
                
            if(!element.gsm2){
                element.gsm2='';
            }

            if(!element.livreur){
                element.livreur=0;
            }

            if(!JSON.parse(localStorage.getItem("User_informations"))['gsm2']){
                var user_gsm2='';
            } else {
                user_gsm2=JSON.parse(localStorage.getItem("User_informations"))['gsm2']
            }
            if(!JSON.parse(localStorage.getItem("User_informations"))['mf']){
                var mf='';
            } else {
                mf=JSON.parse(localStorage.getItem("User_informations"))['mf']
            }
           

            outputItems.push(

                [
                    {
                        stack:  [
                                [
                                    
                                    {
                                        table: {
                                            widths: [250, 300],
                                            fontSize: 10,
                                            margin: 40,
                                            headerRows: 0,
                                            body: [
                                              [
                                                {
                                                    margin: [0, 0, 0, 0],
                                                    border: [false, false, false, false],
                                                    fontSize: 13,
                                                    bold: true,
                                                    text: ' Bordereau de livraison ' ,
                                                }, 
                                                {
                                                    margin: [0, 0, 15, 0],
                                                    border: [false, false, false, false],
                                                    fontSize: 13,
                                                    alignment: 'right',
                                                    bold: true,
                                                    text:''
                                                }

                                            ],
                                              [
                                                {
                                                    columns: [
                                                     {
                                                        image: image_logo,
                                                        width: 100,
                                                        dontBreakRows: true,
                                                      },

                                                      
                                                        {
                                                            text: 'Transporteur: '+transport?.name+ '\n' +'MF:'+transport?.matricule_fiscal+'\n' +'Adresse: '+transport?.adresse+'\n' + 'DISPATCH:  C ' + element?.livreur ,
                                                            fontSize: 10, 
                                                            bold: true,
                                                            dontBreakRows: true,
                                                            margin: [5, 5, 5, 5],
                                                            alignment: 'left',
                                                            color: 'black'
                                                        },
                                                    
                                                    ],
                                                    dontBreakRows: true,
                                                },
                                                
                                                {
                                                    columns: 
                                                    [
                                                        {
                                                            qr:element.ean,
                                                            fit:50,
                                                            width: '20%',
                                                            margin: [5, 5, 5, 5],
                                                            },
                                                        {
                                                            image: this.textToBase64Barcode(element.ean),
                                                            width: 180,
                                                            height: 50,
                                                            alignment: 'right',
    
                                                        },

                                                    ]
                                                    

                                                },
                                           
                                            ],
                                            
                                            [
                                              
                                              {
                                                  text: '', // You can add a specific character or text for the line
                                                  margin: [0, 0, 0, 30], // Adjust the top and bottom margin to control the line spacing
                                                  border: [false, false, false, true], // Set the bottom border only for the line
                                                  borderColor: '#FF0000',
                                              },
                                              {
                                                border: [false, false, false, true],
                                                margin: [0, 0, 0, 30],
                                                borderColor: '#FF0000',
                                                  text: '', // Adjust this if you want to add some text above or below the line
                                              },
                                            ],
                                                [
                                                    {
                                                        margin: [0, 20, 0, 0],
                                                        border: [false, false, false, false],
                                                        fontSize: 14,
                                                        bold: true,
                                                        text: 'Bon de livraison N°: ' + element.id ,
                                                    }, 
                                                    {
                                                        margin: [0, 0, 15, 0],
                                                        border: [false, false, false, false],
                                                        fontSize: 13,
                                                        alignment: 'right',
                                                        bold: true,
                                                        text:''
                                                    }

                                                ],       
                                                [
                                                  {
                                                      margin: [0, 0, 0, 0],
                                                      border: [false, false, false, false],
                                                      fontSize: 11,
                                                      bold: true,
                                                      text: 
                                                      'Date: '+formatDate(new Date(element.created_at), 'dd/MM/yyyy', 'en') + '-  D: ' + element.livreur,
                                                  }, 
                                                  {
                                                      margin: [0, 0, 15, 0],
                                                      border: [false, false, false, false],
                                                      fontSize: 13,
                                                      alignment: 'right',
                                                      bold: true,
                                                      text:''
                                                  }

                                              ],                                            
                                                [
                                                    {
                                                        margin: [0, 15, 0, 15],
                                                        border: [false, false, false, false],
                                                        fontSize: 12,
                                                        lineHeight: 1.3,
                                                        text: "EXPEDITEUR \n" + "Nom : "+ JSON.parse(localStorage.getItem("User_informations"))['name'] +  " \nTelephone :"+ JSON.parse(localStorage.getItem("User_informations"))['gsm'] +  "\n MF ou CIN : "+ mf +  "\n Adresse: "+ JSON.parse(localStorage.getItem("User_informations"))['adresse']
                                                    }, 
                                                    {
                                                        margin: [0, 15, 0, 15],
                                                        border: [false, false, false, false],
                                                        fontSize: 12,
                                                        lineHeight: 1.3,
                                                        text: "DESTINATAIRE \n" + "Nom : "+ element.client +  " \nTelephone :"+ element.gsm1 + " - " + element.gsm2 +  "\n Adresse: " + element.adresse + " -" + element.governorat 
                                                    }

                                                ],
                                 
                                   
                                                
                                            ]
                                        },
                                    },
                                    {
                                        table: {

                                            widths: [80, 200, 100, 50, 95],
                                            alignment: 'center',
                                            body: [
                                                ['Référence	', 'Designation', 'P.U', 'Qte', 'Total.U'],
                                                [element.id, element.designation, (element.cod * 81) / 100, 1, (element.cod * 81) / 100],
                                                [
                                                    {
                                                        colSpan: 3,
                                                        rowSpan: 2,
                                                        border: [false, false, false, false],
                                                        fillColor: '#eeeeee',
                                                        text: this.renderechange(element,_i),
                                                    },
                                                    ' ',
                                                    ' ',
                                                    'Total HT',
                                                    (element.cod * 81) / 100
                                                ],
                                                [
                                                    ' ', ' ', ' ', 'TVA ', '19%'

                                                ],
                                                [
                                                    {
                                                        colSpan: 3,
                                                        rowSpan: 1,
                                                        border: [false, false, false, false],
                                                        text: this.renderFE(element),
                                                        fontSize: 21,
                                                        bold: true
                                                    },
                                                     ' ', ' ',
                                                    'Total',{
                                                        text: element.cod,
                                                        fontSize: 15,
                                                        bold: true
                                                    }
                                                ],
                                            ]

                                        }
                                    },
                                    {
                                        table: {
                                            body: [
                                                [
                                                    {
                                                        text: '\n\n', margin: [20, 20, 20, 8],
                                                        border: [false, false, false, false]
                                                    }
                                                ]
                                            ]
                                        }
                                    }
                                
                                ]
                            ], id:"mediaRow",
                        
                    }, 

                ],
            );
            }
        });
        let docDefinition = {
            pageSize: 'A4',
            dontBreakRows: true,
            defaultStyle: {
                font: 'AlMarai'
              },
            pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
                return currentNode.id === 'mediaRow' && currentNode.pageNumbers.length > 1;},
            content: [outputItems],
            margin:0,
            styles: {
                superMargin: {
                    fontSize: 9,
                    margin: 5,
                },
                header: {
                    fontSize: 24,
                    alignment: 'right',
                    bold: true
                },
               
            }
        }

        pdfMake.fonts = {
          AlMarai: {
            normal: this.SSL+"://client."+this.DOMAIN+this.FONT+ "Almarai-Regular.ttf",
            bold: this.SSL+"://client."+this.DOMAIN+this.FONT+ "Almarai-Bold.ttf",
            light: this.SSL+"://client."+this.DOMAIN+this.FONT+ "Almarai-Light.ttf",
          }

        }

        pdfMake.createPdf(docDefinition).open();

    }



  async GeneratePdfVoucherPerso(colis_list: Colis[]) {
    var image_logo = await this.getBase64ImageFromURL(
      this.SSL +
        "://client." +
        this.DOMAIN +
        "/logo1.png?auto=compress&cs=tinysrgb&dpr=1&w=80"
    );
    var outputItems = [];
    colis_list.forEach((element, index) => {
      for (var _i = 0; _i < element.nbre; _i++) {
        if (!element.gsm2) {
          element.gsm2 = "";
        }

        if (!element.livreur) {
          element.livreur = 0;
        }

        if (!JSON.parse(localStorage.getItem("User_informations"))["gsm2"]) {
          var user_gsm2 = "";
        } else {
          user_gsm2 = JSON.parse(localStorage.getItem("User_informations"))[
            "gsm2"
          ];
        }
        if (!JSON.parse(localStorage.getItem("User_informations"))["mf"]) {
          var mf = "";
        } else {
          mf = JSON.parse(localStorage.getItem("User_informations"))["mf"];
        }

        

        outputItems.push([
          {
            stack: [
              [
                {
                  table: {
                    widths: ['*', '*'],
                    margin: 2,
                    fontSize: 8,
                    headerRows: 0,
                    body: [
                      [
                        [
                          {
                            image: image_logo,
                            width: 50,
                            dontBreakRows: true,
                          },

                          {
                            margin: [0, 0, 0, 0],
                            border: [false, false, false, false],
                            fontSize: 7,
                            bold: true,
                            // alignment: "right",
                            text:
                              "BL N°: BL-" +
                              element.ean +
                              "\n" +
                              "Date: " +
                              formatDate(
                                new Date(element.created_at),
                                "dd/MM/yyyy",
                                "en"
                              ) +
                              "-  D: " +
                              element.livreur 
                          },
                        ],
                        [
                          {
                            image: this.textToBase64Barcode(element.ean),
                            border: [false, false, false, false],
                            width: 80,
                            height: 41,
                            //alignment: "right",
                          },
                        ],
                      ],
                      [
                        [
                          {
                            margin: [0, 0, 0, 0],
                            border: [false, false, false, false],
                            fontSize: 7,
                            lineHeight: 1,
                            text:
                              "EXPEDITEUR \n" +
                              "Nom : " +
                              JSON.parse(
                                localStorage.getItem("User_informations")
                              )["name"] +
                              " \nTelephone :" +
                              JSON.parse(
                                localStorage.getItem("User_informations")
                              )["gsm"] +
                              "\n MF ou CIN : " +
                              mf +
                              "\n Adresse: " +
                              JSON.parse(
                                localStorage.getItem("User_informations")
                              )["adresse"],
                          },
                        ],
                        [
                          {
                            margin: [0, 0, 0, 0],
                            border: [false, false, false, false],
                            fontSize: 7,
                            lineHeight: 1,
                            text:
                              "DESTINATAIRE \n" +
                              "Nom : " +
                              element.client +
                              " \nTelephone :" +
                              element.gsm1 +
                              " - " +
                              element.gsm2 +
                              "\n Adresse: " +
                              element.adresse +
                              " -" +
                              element.governorat,
                          },
                        ],
                      ],

                      [
                        [
                          {
                          text: 'TOTAL',
                          fontSize: 15,
                          bold: true
                          }
                        ],
                      [
                        {
                        text: element.cod,
                        fontSize: 15,
                        bold: true
                        }
                      ]
                   ],
                   [
                    [
                      {
                        fontSize: 8,
                        border: [false, false, false, false],
                        fillColor: '#eeeeee',
                        text: this.renderechange(element,_i),
                    }
                    ],
                    [{
                      border: [false, false, false, false],
                      text: this.renderFE(element),
                       fontSize: 8,
                      bold: true
                    }
                    ]
                    ]
                      
                    ],
                  },
                },
              ],
            ],
            id: "mediaRow",
          },
        ]);
      }
    });
    let docDefinition = {
      pageSize: {
        width: JSON.parse(localStorage.getItem("User_informations"))["width"],
        height: JSON.parse(localStorage.getItem("User_informations"))["height"],
      },
      pageMargins: [ 5, 20, 5, 5 ],
      dontBreakRows: true,
      defaultStyle: {
        font: "AlMarai",
      },
      pageBreakBefore: function (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) {
        return (
          currentNode.id === "mediaRow" && currentNode.pageNumbers.length > 1
        );
      },
      content: [outputItems],
      margin: 0,
      styles: {
        superMargin: {
          fontSize: 9,
          margin: 0,
        },
        header: {
          fontSize: 24,
          alignment: "right",
          bold: true,
        },
      },
    };

    pdfMake.fonts = {
      AlMarai: {
        normal:
          this.SSL +
          "://client." +
          this.DOMAIN +
          this.FONT +
          "Almarai-Regular.ttf",
        bold:
          this.SSL +
          "://client." +
          this.DOMAIN +
          this.FONT +
          "Almarai-Bold.ttf",
        light:
          this.SSL +
          "://client." +
          this.DOMAIN +
          this.FONT +
          "Almarai-Light.ttf",
      },
    };

    pdfMake.createPdf(docDefinition).open();




  }


async GeneratePdfVoucher(colis_list: Colis[]){
  if(JSON.parse(localStorage.getItem("User_informations"))["personalised"]==1) {
this.GeneratePdfVoucherPerso(colis_list);         
  } else {
    this.GeneratePdfVoucherA4(colis_list);    

  }
}



  renderechange(element: any, _i: number) {
    var text = "Accepte Chéque ";

    if (element.remarque) {
      text = text + "\n" + element.remarque;
    }
    if (element.nbre) {
      text = text + "\n" + "Nombre: " + (_i + 1) + "/" + element.nbre;
    }

    return text;
  }

  renderFE(element: any) {
    var text = "";
    if (element.echange) {
      text = "ECHANGE    ";
    }
    if (element.decharge) {
      text = text + "-    FRAGILE";
    }
    return text;
  }

  buildTableBody(data, columns) {
    var body = [];
    body.push(columns);
    data.forEach(function (row) {
      var dataRow = [];
      columns.forEach((column, index) => {
        dataRow.push(row[column]);
      });
      body.push(dataRow);
    });

    return body;
  }

  table(data, columns) {
    return {
      table: {
        heights: 25,
        fontSize: 9,
        dontBreakRows: true,
        headerRows: 1,
        body: this.buildTableBody(data, columns),
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? "#efefef" : null;
        },
      },
      style: "superMargin",
    };
  }

  Totalcod(colis_list: Colis[]) {
    var cod: number = 0;
    for (var i = 0; i < colis_list.length; i++) {
      cod = cod + +colis_list[i].cod;
    }
    return cod;
  }

  //PDF pour le Bon de Sortie
  async GeneratePdfListColis(colis_list: Colis[]) {
    var tempArr = [];
    for (var i = 0; i < colis_list.length; i++) {
      tempArr.push({
        EAN: {
          image: this.textToBase64Barcode(colis_list[i].ean),
          width: 90,
          height: 40,
        },
        Client: colis_list[i].client,
        Telephone: colis_list[i].gsm1,
        Governorat: colis_list[i].governorat,
        Designation: colis_list[i].designation,
        COD: colis_list[i].cod,
      });
    }

    let docDefinition = {
      defaultStyle: {
        font: "AlMarai",
      },
      content: [
        {
          columns: [
            {
              text: "BON DE SORTIE ",
              fontSize: 24,
              bold: true,
              margin: 5,
              color: "black",
            },
            {
              image: await this.getBase64ImageFromURL(
                this.SSL +
                  "://client." +
                  this.DOMAIN +
                  "/logo1.png?auto=compress&cs=tinysrgb&dpr=1&w=80"
              ),
              width: 150,
            },
          ],
        },
        {
          columns: [
            [
              {
                text: "INFORMATIONS PICK-UP",
                bold: true,
                margin: 5,
              },
              {
                text: `Date: ${formatDate(new Date(), "dd/MM/yyyy", "en")}`,
                fontSize: 9,
                margin: 5,
              },
              {
                text:
                  `CLIENT: ` +
                  JSON.parse(localStorage.getItem("User_informations"))["name"],
                fontSize: 10,
                bold: true,
                margin: 5,
              },
              {
                text: `Nbr de Colis: ${colis_list.length}`,
                fontSize: 10,
                bold: true,
                margin: 5,
              },
              {
                text: `Total Cod: ${this.Totalcod(colis_list)}`,
                fontSize: 10,
                bold: true,
                margin: 5,
              },
            ],
          ],
        },

        this.table(tempArr, [
          "EAN",
          "Client",
          "Telephone",
          "Designation",
          "Governorat",
          "COD",
        ]),
      ],
      styles: {
        superMargin: {
          fontSize: 9,
          margin: 5,
        },
      },
    };

    pdfMake.fonts = {
      AlMarai: {
        normal:
          this.SSL +
          "://client." +
          this.DOMAIN +
          this.FONT +
          "Almarai-Regular.ttf",
        bold:
          this.SSL +
          "://client." +
          this.DOMAIN +
          this.FONT +
          "Almarai-Bold.ttf",
        light:
          this.SSL +
          "://client." +
          this.DOMAIN +
          this.FONT +
          "Almarai-Light.ttf",
      },
    };
    pdfMake.createPdf(docDefinition).open();
  }



  async GeneratePdfListColisPaiement(colis_list: Colis[], recu: Recu, client: Client) {
    var tempArr = [];
    for (var i = 0; i < colis_list.length; i++) {
        tempArr.push(
            {
                EAN:  colis_list[i].ean,
                Client: colis_list[i].client,
                Telephone: colis_list[i].gsm1,
                Statut: this.getTextStatut(colis_list[i].last_statut),
                COD: colis_list[i].cod,
            }
        );
    }
    let docDefinition = {
        defaultStyle: {
            font: 'AlMarai'
          },
        content: [
            {
                columns: [
                    {
                        text: "RECU DE PAIEMENT : "+ recu.id + "\n"+ formatDate(new Date(recu.created_at), 'dd/MM/yyyy', 'en')  ,
                        fontSize: 18,
                        bold: true,
                        margin: 5,
                        color: 'black'
                    },
                   

                ],
            },
            {
            columns: [
 
                {
                    table: {
                        widths: [230],
                        body: [
                            [
                                {
                                    text: 'Client: ' + client.name + '\n\n' + 'Telephone: ' + client.gsm + '\n\n' + 'Adresse: ' + client.adresse,
                                    fontSize: 10,
                                    margin: [5, 10, 5, 10],
                                    alignment: 'left',
                                    bold: true,
                                    color: 'black',
                                    width: '45%',
                                }
                            ]
                        ]
                    }
                }

            ],

            margin: [0, 10, 0, 10],
        },
            {
                columns: [
                    [
                        {
                            text: ` Nombre de colis:  ${colis_list.length}  -  Colis Livrés: ${recu.nbre_colis}  -  Colis Retour: ${colis_list.length - recu.nbre_colis}`,
                            bold: true,
                            margin: 5,
                        },
                        {
                            text: `Total COD: ${recu.total_cod} DT   -   Frais De Livraison: ${recu.frais} DT`,
                            fontSize: 11,
                            bold: true,
                            margin: 5,
                        },
                        {
                            text: `Net A Payer: ${recu.total_cod - recu.frais} DT`,
                            bold: true,
                            margin: 5,
                        }
                    ],
                ]
            },

            this.table(tempArr, ['EAN', 'Client', 'Telephone', 'Statut', 'COD'])
        ],
        styles: {
            titel1: {
                fontSize: 22,
                bold: true,
                alignment: 'left',
                margin: [0, 5, 0, 3],
            },
            titel2: {
                fontSize: 10,
                alignment: 'left'
            },
            superMargin: {
                fontSize: 9,
                margin: 5,
            }
        }

    };
    pdfMake.fonts = {
      AlMarai: {
        normal: this.SSL+"://client."+this.DOMAIN+this.FONT+ "Almarai-Regular.ttf",
        bold: this.SSL+"://client."+this.DOMAIN+this.FONT+ "Almarai-Bold.ttf",
        light: this.SSL+"://client."+this.DOMAIN+this.FONT+ "Almarai-Light.ttf",
      }
  }

 
    pdfMake.createPdf(docDefinition).open();
}


getTextStatut(param) {
  var stat: Statut[];
  stat = JSON.parse(localStorage.getItem("statutsList"));
  for (let element of stat) {
      if (element.id === param) {
          return element.designation;
      }
  }
}


}
